<template>
  <div>Register</div>
</template>

<script>
export default {
  name: 'Register',

  created() {
  }
}
</script>
